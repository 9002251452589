<template>
    <div class="simple-card" :style="simpleCardStyles" :class="componentClasses">
        <template v-for="content in blok.cardContent" :key="content._uid">
            <Component :is="content.component" :blok="content" />
        </template>
        <div v-if="blok.footerContent.length" class="footer-wrapper">
            <template v-for="content in blok.footerContent" :key="content._uid">
                <Component :is="content.component" :blok="content" />
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { ICoreSimpleCard } from '~ui/types/components/CoreSimpleCard';
import { buildBackground } from '~ui/utils/styleBuilder';
import { hexToRgba } from '~ui/utils/hexToRGBA';

const { blok } = defineProps<{ blok: ICoreSimpleCard }>();
const background = buildBackground(blok.background?.[0]);

const shadowColor = blok.boxShadowColor?.color
    ? hexToRgba(blok.boxShadowColor?.color, Number(blok.boxShadowOpacity ?? 100) * 0.01)
    : null;

const simpleCardStyles = computed(() => {
    return {
        '--border-top-left-radius': blok.borderTopLeftRadius ? `${blok.borderTopLeftRadius}px` : null,
        '--border-top-right-radius': blok.borderTopRightRadius ? `${blok.borderTopRightRadius}px` : null,
        '--border-bottom-right-radius': blok.borderBottomRightRadius ? `${blok.borderBottomRightRadius}px` : null,
        '--border-bottom-left-radius': blok.borderBottomLeftRadius ? `${blok.borderBottomLeftRadius}px` : null,
        '--box-shadow': shadowColor,
        '--border-color': blok.borderColor?.color || null,
        '--row-gap': blok.rowGap ? `${blok.rowGap}px` : null,
        ...background,
    };
});

const componentClasses = computed(() => {
    return [blok.componentDirection];
});
</script>

<style lang="postcss" scoped>
.simple-card {
    @apply flex flex-col transition-shadow duration-[0.3s] ease-[ease] h-full overflow-hidden rounded-[10px_10px_0_0] border border-solid;
    border-radius: var(--border-top-left-radius) var(--border-top-right-radius) var(--border-bottom-right-radius)
        var(--border-bottom-left-radius);
    box-shadow: 0 8px 16px 0 var(--box-shadow);
    row-gap: var(--row-gap);
    border-color: var(--border-color, #e9e9f0);
    &.horizontal {
        @apply md:flex-row md:items-center;
        .footer-wrapper {
            @apply mt-0 py-5;
        }
    }
    .footer-wrapper {
        @apply mt-auto pb-5;
    }
    background {
        @apply hidden;
    }
    > *:not(img) {
        @apply px-5;
    }
}
</style>
